<template>
	<div class="pnv--container">
		<h1>{{ headline }}</h1>
		<div class="pnv--content">
			<div class="pnv--navigation">
				<div class="pnv--navigation--top">
					<div
						v-show="getNavigation.arrow === 'left'"
						class="pnv--navigation--arrow pnv--navigation--arrow--left"
					></div>
					<div
						v-show="getNavigation.arrow === 'right'"
						class="pnv--navigation--arrow pnv--navigation--arrow--right"
					></div>
					<div
						v-show="getNavigation.arrow === 'straight'"
						class="pnv--navigation--arrow pnv--navigation--arrow--straight"
					></div>
					<div
						v-show="getNavigation.arrow === 'destination'"
						class="pnv--navigation--arrow pnv--navigation--arrow--destination"
					></div>
					<div
						class="pnv--navigation--top--right"
					>
						<span>{{ getNavigation.text }}</span>
						<span>{{ getNavigation.street }}</span>
					</div>
				</div>
				<div class="pnv--navigation--bottom">
					<div class="pnv--navigation--bottom--pane">
						<span>{{ content.arrival }}</span>
						<span>Arrival</span>
					</div>
					<div class="pnv--navigation--bottom--pane">
						<span>{{ content.travel_time }} mins</span>
						<span>Travel Time</span>
					</div>
				</div>
			</div>
			<ul>
				<li
					v-for="(item, index) in content.jobs"
					:key="index"
				>
					<h1 v.show="item.type">{{ item.type }}</h1>
					<div
						class="pnv--navigation--list--adress"
					>
						{{ item.value }}
					</div>
				</li>
			</ul>
		</div>
		<div
			v-show="showButtonGroup"
			class="psv--decision--container"
		>
			<div class="psv--decision--icon"></div>
			<div
				class="psv--decision--streetname"
			>
				<span
					v-if="getStoppointRouteDecision === 2"
				>
					New Stop Point
				</span>
				<span
					v-else
				>
					Recommended Stop Point
				</span>
				<span>
					{{ content.recommended_stoppoint.adress }}
				</span>
			</div>
			<div
				v-show="showButtons"
				class="psv--decision--buttoncontainer"
			>
				<LUIButton
					button-label="Yes"
					membrane="aqua"
					size="medium"
					:click-event="decisionYes"
					:primary="true"
				></LUIButton>
				<LUIButton
					button-label="No"
					membrane="aqua"
					size="medium"
					:click-event="decisionNo"
					:primary="true"
				></LUIButton>
			</div>
			<div
				v-show="showSaveButton"
				class="psv--decision--buttoncontainer single--button"
			>
				<LUIButton
					button-label="Save stop point"
					membrane="aqua"
					size="medium"
					:click-event="saveSpot"
					:primary="true"
				></LUIButton>
			</div>
		</div>
	</div>
</template>

<script>
	import LUIButton from "./LUIButton"
	import { mapGetters } from "vuex"

	export default {
		name: "PhoneStoppointView",
		components: {
			LUIButton
		},
		props: {
			headline: {
				type: String,
				default: ""
			},
			content: {
				type: Object,
				default() {
					return {}
				}
			},
			showButtons: {
				type: Boolean
			},
			showSaveButton: {
				type: Boolean
			}
		},
		computed: {
			...mapGetters([
				"getReachedDestination",
				"getStoppointRouteDecision",
				"getNavigation"
			]),
			showButtonGroup: function () {
				//if (this.showButtons || this.showSaveButton) {
					return true
				//}
			}
		},
		methods: {
			decisionYes: function () {
				this.$store.commit("setStoppointRouteDecision", 1)
				this.$store.commit("hideDecisionButtons")
			},
			decisionNo: function () {
				this.$store.commit("setStoppointRouteDecision", 2)
				this.$store.commit("hideDecisionButtons")
			},
			saveSpot: function () {
				this.$store.commit("setSaveMarker", true)
				this.$store.commit("showSaveButton", false)

				this.$store.commit("setNavigation", {
					text: "New stop point saved",
					time: "",
					arrow: "destination",
					street: ""
				})
			}
		}
	}
</script>

<style scoped>
	h1 {
		color: #fff;
		font-family: "Fira Sans", sans-serif;
		font-family: "FiraGO Bold", sans-serif;
		font-size: 1.2rem;
		margin: 0 0 1rem 0;
		text-align: center;
	}
	
	ul {
		width: 80%;
		list-style: circle url("../../statics/images/icons/bulletpoint.png");
	}

	li {
		color: #fff;
		margin-bottom: 0.875rem;
		padding: 0 1rem;
		font-family: "FiraGo Regular", sans-serif;
	}

	li h1 {
		font-size: 1.3rem;
		font-family: "FiraGo Light", sans-serif;
		margin: 0 0 0.2rem 0;
		text-align: left;
	}

	li:first-child {
		margin-bottom: 2rem;
	}

	li:first-child h1 {
		font-family: "FiraGo Bold", sans-serif;
	}

	.pnv--content {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.pnv--navigation {
		background: #08A0FF;
		display: flex;
		flex-direction: column;
		color: #fff;
		width: 90%;
	}

	.pnv--navigation--top {
		display: flex;
		flex-direction: row;
		align-items: center;
		height: 6.5rem;
	}

	.pnv--navigation--top img {
		width: 45%;
		height: 45%;
	}

	.pnv--navigation--top--right {
		display: flex;
		flex-direction: column;
		font-family: "FiraGo Light", sans-serif;
		justify-content: center;
		align-items: center;
		width: 70%;
	}

	.pnv--navigation--top--right span:first-child {
		font-size: 1.5rem;
		text-align: center;
	}

	.pnv--navigation--top--right span:last-child {
		font-size: 1rem;
		line-height: 1.25rem;
	}

	.pnv--navigation--bottom {
		display: flex;
		flex-direction: row;
		height: 3.5rem;
	}

	.pnv--navigation--bottom--pane {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 50%;
	}

	.pnv--navigation--bottom--pane:first-child {
		background: #08A0FF;
	}

	.pnv--navigation--bottom--pane:last-child {
		background: #0B94EA;
	}

	.pnv--navigation--bottom--pane span:first-child {
		font-size: 1rem;
	}

	.pnv--navigation--bottom--pane span:last-child {
		font-size: 0.8rem;
	}

	.pnv--navigation--list--adress,
	.pnv--navigation--list--eta {
		font-size: 1.3rem;
		font-family: "FiraGo Regular", sans-serif;
	}

	.pnv--navigation--list--eta {
		font-family: "FiraGo Light", sans-serif;
		margin-top: 0.5rem;
	}

	.psv--decision--container {
		width: 90%;
		margin: 0 5%;
		position: relative;
	}

	.psv--decision--streetname {
		font-family: "FiraGo Light", sans-serif;
		background: #14ABF5;
		height: 5rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		color: #fff;
		text-align: center;
	}

	.psv--decision--streetname span:first-child {
		font-size: 1.2rem;
		margin-bottom: 0.25rem;
	}

	.psv--decision--streetname span:last-child {
		font-size: 0.75rem;
	}

	.psv--decision--buttoncontainer {
		display: flex;
		flex-direction: row;
		justify-content: center;
	}

	.psv--decision--buttoncontainer button {
		margin: 0.5rem 0;
	}

	.psv--decision--buttoncontainer button:nth-child(2) {
		margin-left: 0.54rem;
	}

	.psv--decision--icon {
		width: 3rem;
		height: 4rem;
		position: absolute;
		background: url("../../statics/images/icons/parking.svg");
		background-repeat: no-repeat;
		background-size: contain;
		left: calc(50% - 1.5rem);
		top: -2.5rem;
	}

	.single--button .button-medium {
		margin-right: 0;
		width: 100% !important;
	}

	.pnv--navigation--arrow {
		height: 5rem;
		width: 5rem;
	}

	.pnv--navigation--arrow--left {
		background: url("../../statics/images/nav-arrow-left.svg");
		background-repeat: no-repeat;
		background-size: 3rem;
		background-position: center center;
	}

	.pnv--navigation--arrow--right {
		background: url("../../statics/images/nav-arrow-right.svg");
		background-repeat: no-repeat;
		background-size: 3rem;
		background-position: center center;
	}

	.pnv--navigation--arrow--straight {
		background: url("../../statics/images/nav-arrow-straight.svg");
		background-repeat: no-repeat;
		background-size: 2rem;
		background-position: center center;
	}

	.pnv--navigation--arrow--destination {
		background: url("../../statics/images/nav-arrow-destination.svg");
		background-repeat: no-repeat;
		background-size: 2rem;
		background-position: center center;
	}
</style>
