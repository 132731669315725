<template>
	<div class="pnv--container">
		<h1>{{ headline }}</h1>
		<div class="pnv--content">
			<div class="pnv--navigation">
				<div class="pnv--navigation--top">
					<div
						v-show="getNavigation.arrow === 'left'"
						class="pnv--navigation--arrow pnv--navigation--arrow--left"
					></div>
					<div
						v-show="getNavigation.arrow === 'right'"
						class="pnv--navigation--arrow pnv--navigation--arrow--right"
					></div>
					<div
						v-show="getNavigation.arrow === 'straight'"
						class="pnv--navigation--arrow pnv--navigation--arrow--straight"
					></div>
					<div
						v-show="getNavigation.arrow === 'destination'"
						class="pnv--navigation--arrow pnv--navigation--arrow--destination"
					></div>
					<div
						class="pnv--navigation--top--right"
					>
						<span>{{ getNavigation.text }}</span>
						<span>{{ getNavigation.street }}</span>
					</div>
				</div>
				<div class="pnv--navigation--bottom">
					<div class="pnv--navigation--bottom--pane">
						<span>{{ content.arrival }} + {{ content.arrival_delay}}</span>
						<span>Arrival</span>
					</div>
					<div class="pnv--navigation--bottom--pane">
						<span>{{ getNavigation.time }}</span>
						<span>Travel Time</span>
					</div>
				</div>
			</div>
			<div class="phone--list--lineconnection"></div>
			<ul>
				<li
					v-for="(item, index) in content.routing_points"
					:key="index"
				>
					<h1>{{ item.headline }}</h1>
					<div
						class="pnv--navigation--list--adress"
					>
						{{ item.adress_one }}
					</div>
					<div
						class="pnv--navigation--list--adress"
					>
						{{ item.adress_two }}
					</div>
					<div
						v-if="index === 0"
						class="pnv--navigation--list--eta"
					>
						<span class="pnv--navigation--list--eta--item">
							ETA planned: {{ item.eta.planned }}
						</span>
						<span class="pnv--navigation--list--eta--item">
							ETA actual: {{ item.eta.actual }} + {{ item.eta.difference }}
						</span>
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from "vuex"

	export default {
		name: "PhoneNavigationView",
		props: {
			headline: {
				type: String,
				default: ""
			},
			content: {
				type: Object,
				default() {
					return {}
				}
			}
		},
		computed: {
			...mapGetters([
				"getReachedDestination",
				"getNavigation"
			])
		}
	}
</script>

<style scoped>
	h1 {
		color: #fff;
		font-family: "Fira Sans", sans-serif;
		font-family: "FiraGO Bold", sans-serif;
		font-size: 1.2rem;
		margin: 0 0 1rem 0;
		text-align: center;
	}
	
	ul {
		width: 80%;
		list-style: circle url("../../statics/images/icons/bulletpoint.png");
	}

	li {
		color: #fff;
		margin-bottom: 0.875rem;
		padding: 0 1rem;
		font-family: "FiraGo Regular", sans-serif;
	}

	li h1 {
		font-size: 1.3rem;
		font-family: "FiraGo Light", sans-serif;
		margin: 0 0 0.2rem 0;
		text-align: left;
	}

	li:first-child {
		margin-bottom: 2rem;
	}

	li:first-child h1 {
		font-family: "FiraGo Bold", sans-serif;
	}

	.pnv--container {
		height: 100%;
		overflow-x: hidden;
		overflow-y: scroll;
	}

	.pnv--content {
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;
	}

	.pnv--navigation {
		background: #08A0FF;
		display: flex;
		flex-direction: column;
		color: #fff;
		width: 90%;
	}

	.pnv--navigation--top {
		display: flex;
		flex-direction: row;
		align-items: center;
		height: 6.5rem;
	}

	.pnv--navigation--top img {
		width: 30%;
		height: 45%;
	}

	.pnv--navigation--top--right {
		display: flex;
		flex-direction: column;
		font-family: "FiraGo Light", sans-serif;
		justify-content: center;
		align-items: center;
		width: 70%;
	}

	.pnv--navigation--top--right span:first-child {
		font-size: 1.5rem;
		text-align: center;
	}

	.pnv--navigation--top--right span:last-child {
		font-size: 1rem;
		line-height: 1.25rem;
	}

	.pnv--navigation--bottom {
		display: flex;
		flex-direction: row;
		height: 3.5rem;
	}

	.pnv--navigation--bottom--pane {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 50%;
	}

	.pnv--navigation--bottom--pane:first-child {
		background: #08A0FF;
	}

	.pnv--navigation--bottom--pane:last-child {
		background: #0B94EA;
	}

	.pnv--navigation--bottom--pane span:first-child {
		font-size: 1rem;
	}

	.pnv--navigation--bottom--pane span:last-child {
		font-size: 0.8rem;
	}

	.pnv--navigation--list--adress,
	.pnv--navigation--list--eta {
		font-size: 0.7rem;
	}

	.pnv--navigation--list--eta {
		font-family: "FiraGo Light", sans-serif;
		margin-top: 0.5rem;
	}

	.phone--list--lineconnection {
		position: absolute;
		width: 0.125rem;
		height: 60%;
		background: #fff;
		top: 12rem;
		left: 1.75rem;
	}

	.pnv--navigation--arrow {
		height: 5rem;
		width: 5rem;
	}

	.pnv--navigation--arrow--left {
		background: url("../../statics/images/nav-arrow-left.svg");
		background-repeat: no-repeat;
		background-size: 3rem;
		background-position: center center;
	}

	.pnv--navigation--arrow--right {
		background: url("../../statics/images/nav-arrow-right.svg");
		background-repeat: no-repeat;
		background-size: 3rem;
		background-position: center center;
	}

	.pnv--navigation--arrow--straight {
		background: url("../../statics/images/nav-arrow-straight.svg");
		background-repeat: no-repeat;
		background-size: 2rem;
		background-position: center center;
	}

	.pnv--navigation--arrow--destination {
		background: url("../../statics/images/nav-arrow-destination.svg");
		background-repeat: no-repeat;
		background-size: 2rem;
		background-position: center center;
	}
</style>
