<template>
	<div class="sc--places--container">
		<div class="sc--places--headline">
			HERE Fleet Optimization API &ndash; Re-planning Mode
		</div>
		<div class="sc--places--subheadline">
			Inclusion of new, high-priority jobs
		</div>
		<div
			v-show="!getShowApiView && !getShowApiProcessView"
			class="sc--places--description sc--exceptionicon--big"
		>
			<img src="../../statics/images/icons/destination-pin.svg" />
		</div>
		<div
			v-show="getShowApiView && !getShowApiProcessView"
			class="sc--places--description"
		>
			<img class="img--api" src="../../statics/images/api-animation-placeholder.png" />
		</div>
		<div
			v-show="!getShowApiView && getShowApiProcessView"
			class="sc--places--description"
		>
			<img class="img--api" src="../../statics/images/api-animation.gif" />
		</div>
		<div
			v-show="!getShowApiView && !getShowApiProcessView"
			class="sc--places--selection"
		>
			<LUIButton
				membrane="context"
				button-label="Add Pickup"
				size="medium"
				:click-event="addPickup"
				:primary="true"
			>
			</LUIButton>
		</div>
		<div
			v-show="getShowApiView && !getShowApiProcessView && !getSingleExceptionRoute"
			class="sc--places--selection"
		>
			<LUIButton
				membrane="context"
				button-label="Call API"
				size="medium"
				:click-event="callApi"
				:primary="true"
			>
			</LUIButton>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex"
import LUIButton from "./LUIButton"
export default {
	name: "SidebarContentException",
	components: {
		LUIButton
	},
	computed: {
		...mapGetters([
			"getShowApiView",
			"getShowApiProcessView",
			"getSingleExceptionRoute"
		])
	},
	methods: {
		addPickup: function () {
			this.$store.commit("setAddExceptionMarker", true)
			this.$store.commit("setShowApiView", true)
			this.$store.commit("setShowApiProcessView", false)
		},
		callApi: function () {
			this.$store.commit("setShowApiView", false)
			this.$store.commit("setShowApiProcessView", true)

			setTimeout(() => {
				this.$store.commit("setShowApiView", true)
				this.$store.commit("setShowApiProcessView", false)
				this.$store.commit("setSingleExceptionRoute", true)
				this.$store.commit("setExceptionPopupReplacement", true)
			}, 3000)
		}
	}
}
</script>

<style scoped>
.sc--places--container {
	display: flex;
	flex-direction: column;
	height: 80%;
}

.sc--places--label {
	color: rgba(255, 255, 255, 0.6);
	text-decoration: underline;
	margin-bottom: 1.25em;
	font-size: 0.8125em;
}

.sc--places--headline {
	color: rgba(255, 255, 255, 1);
	margin-bottom: 0.625em;
	font-size: 2em;
	font-family: "FiraGo Bold", sans-serif;
}

.sc--places--subheadline {
	color: rgba(255, 255, 255, 0.6);
	text-transform: uppercase;
	font-family: "FiraGo Medium", sans-serif;
	font-size: 0.8125em;
	margin-bottom: 1.75em;
}

.sc--places--description {
	color: rgba(255, 255, 255, 0.8);
	font-size: 1em;
	line-height: 1.625em;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.sc--places--description img {
	height: 10rem;
}


.sc--places--description .img--api {
	height: 100%;
	width: 100%;
}



.sc--places--button {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

.sc--places--selection {
	margin-top: 1.5em;
	margin-bottom: 1.25em;
	display: flex;
	justify-content: center;
}

.sc--exceptionicon--big {
	margin-top: 10rem;
}
</style>
