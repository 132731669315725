import Vue from "vue"
import VueRouter from "vue-router"
import BrandMembrane from "./views/BrandMembrane"
import AppContainer from "./views/AppContainer"
import MapContainer from "./views/MapContainer"

import SidebarContentBusiness from "./components/SidebarContentBusiness"
import SidebarContentRouting from "./components/SidebarContentRouting"
import SidebarContentMobileSDK from "./components/SidebarContentMobileSDK"
import SidebarContentException from "./components/SidebarContentException"
import SidebarContentStopPoint from "./components/SidebarContentStopPoint"

Vue.use(VueRouter)

const routes = [
	{
		path: "/",
		name: "BrandMembrane",
		component: BrandMembrane
	},
	{
		path: "/app",
		name: "AppContainer",
		component: AppContainer,
		redirect: "/app/business",
		children: [
			{
				path: "/app/business",
				name: "Business",
				components: {
					default: MapContainer,
					sidebar: SidebarContentBusiness
				}
			},
			{
				path: "/app/routing",
				name: "Routing",
				components: {
					default: MapContainer,
					sidebar: SidebarContentRouting
				}
			},
			{
				path: "/app/mobilesdk",
				name: "Mobile SDK",
				components: {
					default: MapContainer,
					sidebar: SidebarContentMobileSDK
				}
			},
			{
				path: "/app/stoppoint",
				name: "Stop Point",
				components: {
					default: MapContainer,
					sidebar: SidebarContentStopPoint
				}
			},
			{
				path: "/app/exception",
				name: "Exception",
				components: {
					default: MapContainer,
					sidebar: SidebarContentException
				}
			}
		]
	}
]

const router = new VueRouter({ routes })

export default router
