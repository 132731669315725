<template>
	<div class="sc--places--container">
		<div class="sc--places--backbutton">
			<button
				class="sc--business--backbutton"
				v-show="getIsDepotSelected"
				@click="navigateBack"
			>
				<img src="../../statics/images/backarrow.svg">
			</button>
		</div>
		<div class="sc--places--headline">
			{{ headline }}
		</div>
		<div class="sc--places--subheadline">
			{{ subheadline }}
		</div>
		<div class="sc--places--description">
		</div>
		<div
			v-show="!getIsDepotSelected"
			class="sc--places--selection"
		>
			<div class="sc--places--subheadline">
				Show layers
			</div>
			<LUICheckbox
				id="orders"
				label="Delivery Orders"
			>
			</LUICheckbox>
			<LUICheckbox
				id="warehouses"
				label="Warehouses"
			>
			</LUICheckbox>
			<LUICheckbox
				id="depots"
				label="Depots"
			>
			</LUICheckbox>
		</div>
		<div
			v-show="getIsDepotSelected"
			class="sc--places--selection"
		>
			<FleetList
				:content="getContentBusiness"
			>
			</FleetList>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex"
import LUICheckbox from "./LUICheckbox"
import FleetList from "./FleetList"

export default {
	name: "SidebarContentBusiness",
	components: {
		LUICheckbox,
		FleetList
	},
	computed: {
		...mapGetters([
			"showLayerOrders",
			"showLayerWarehouses",
			"showLayerDepots",
			"getContentBusiness",
			"getIsDepotSelected"
		]),
		headline: function () {
			if (this.getIsDepotSelected) {
				return "Your fleet"
			} else {
				return "HERE Fleet Optimization API"
			}
		},
		subheadline: function () {
			if (this.getIsDepotSelected) {
				return "Overview of your fleet incl. electric vehicles"
			} else {
				return "Overview of all pick-up and drop-off locations relevant to your business"
			}
		}
	},
	methods: {
		navigateBack: function () {
			this.$store.commit("setIsDepotSelected", false)
		}
	}
}
</script>

<style scoped>
.sc--places--label {
	color: rgba(255, 255, 255, 0.6);
	text-decoration: underline;
	margin-bottom: 1.25em;
	font-size: 0.8125em;
}

.sc--places--headline {
	color: rgba(255, 255, 255, 1);
	margin-bottom: 0.625em;
	font-size: 2em;
	font-family: "FiraGo Bold", sans-serif;
	width: 80%;
}

.sc--places--subheadline {
	color: rgba(255, 255, 255, 0.6);
	text-transform: uppercase;
	font-family: "FiraGo Medium", sans-serif;
	font-size: 0.8125em;
	margin-bottom: 1.75em;
	width: 80%;
}

.sc--places--description {
	color: rgba(255, 255, 255, 0.8);
	font-size: 1em;
	line-height: 1.625em;
	margin-bottom: 4em;
}

.sc--places--button {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

.sc--places--selection {
	margin-top: 1.5em;
	margin-bottom: 1.25em;
}

.sc--places--selection img {
	height: 100%;
	width: 100%;
}

.sc--business--backbutton {
	border: none;
	background: none;
	width: 2.5rem;
	height: 2.5rem;
	margin-bottom: 1rem;
}

.sc--business--backbutton img {
	height: 60%;
}

.sc--business--backbutton:active img,
.sc--business--backbutton:focus img {
	opacity: 0.4;
}
</style>
