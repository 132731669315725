<template>
	<div class="phone--container">
		<div
			class="phone--constraint"
		>
			<PhoneTabbedView
				v-show="!getShowNavigationView"
				headline="App by HERE Customer"
			></PhoneTabbedView>
			<PhoneNavigationView
				v-show="getShowNavigationView"
				headline="App by HERE Customer"
				:content="getContentNavigation"
			>
			</PhoneNavigationView>
		</div>
	</div>
</template>

<script>
import PhoneTabbedView from "./PhoneTabbedView"
import PhoneNavigationView from "./PhoneNavigationView"

import { mapGetters } from "vuex"

export default {
	name: "Phone",
	components: {
		PhoneTabbedView,
		PhoneNavigationView
	},
	computed: {
		...mapGetters([
			"getContentNavigation",
			"getShowNavigationView"
		])
	}
}
</script>

<style scoped>
	.phone--container {
		width: 20rem;
		height: 40rem;
		background: url("../../statics/images/phone-frame.svg");
		background-size: contain;
		background-repeat: no-repeat;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.phone--constraint {
		width: 17.4rem;
		height: 32rem;
	}
</style>
