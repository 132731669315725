<template>
	<div class="phone--container">
		<div
			class="phone--constraint"
		>
			<PhoneStoppointView
				headline="App by HERE Customer"
				:content="getContentNavigationStoppoint"
				:show-buttons="getShowDecisionButtons"
				:show-save-button="getSaveButton"
			>
			</PhoneStoppointView>
		</div>
	</div>
</template>

<script>
import PhoneStoppointView from "./PhoneStoppointView"

import { mapGetters } from "vuex"

export default {
	name: "Phone",
	components: {
		PhoneStoppointView
	},
	computed: {
		...mapGetters([
			"getContentNavigation",
			"getContentNavigationStoppoint",
			"getShowNavigationView",
			"getShowDecisionButtons",
			"getSaveButton"
		])
	}
}
</script>

<style scoped>
	.phone--container {
		width: 20rem;
		height: 40rem;
		background: url("../../statics/images/phone-frame.svg");
		background-size: contain;
		background-repeat: no-repeat;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.phone--constraint {
		width: 17.4rem;
		height: 32rem;
	}
</style>
