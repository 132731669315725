<template>
	<div class="ls--container">
		<div
			v-for="(step, index) in steps"
			:key="index"
			class="ls--item"
			:class="{ 'ls--item-active': index === activeStep }"
			@touchstart="clickEvent(index)"
			@click="clickEvent(index)"
		>
			<div class="ls--item--count">
				{{ index + 1 }}
			</div>
			<div class="ls--item--label">
				{{ step.label }}
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "LUIStepper",
	props: {
		steps: {
			type: Array,
			default() {
				return []
			}
		},
		activeStep: {
			type: Number,
			default: 0
		},
		clickEvent: {
			type: Function,
			default() {
				return () => {}
			}
		}
	}
}
</script>

<style scoped>
.ls--container {
	width: 100%;
	height: 8.125rem;
	display: flex;
	justify-content: center;
}

.ls--item {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	color: #9B9EA2;
	font-family: "FiraGO Regular", sans-serif;
	margin: 0 1rem;
	cursor: pointer;
}

.ls--item-active .ls--item--count {
	background: #2DD5C9;
	color: #F8F8F8;
}

.ls--item-active .ls--item--label {
	color: #2DD5C9;
}

.ls--item--count {
	height: 5rem;
	width: 5rem;
	border-radius: 5rem;
	background: #F8F8F8;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 2.5rem;
	line-height: 3.125rem;
}

.ls--item--label {
	font-size: 0.8125rem;
	line-height: 1.25rem;
}
</style>
