<template>
	<div class="mc--container">
		<Map
			:center="getCenter"
			:zoom="getZoom"
			:orders="getOrders"
			:warehouses="getWarehouses"
			:depots="getDepots"
			:constructionsites="getConstructionsitesMarker"
			:blockings="getBlockingMarker"
			:show-layer-orders="showLayerOrders"
			:show-layer-warehouses="showLayerWarehouses"
			:show-layer-depots="showLayerDepots"
			:show-modal="isModalShown"
			:show-routes="showRoutes"
			:show-single-route="showSingleRoute"
			:trigger-walker="getWalker"
			:show-many-trucks="getShowManyTrucks"
			:add-exception-marker="getAddExceptionMarker"
			:single-exception-route="getSingleExceptionRoute"
			:show-stoppoint-layer="getStoppointLayer"
			:stoppoint-marker="getStoppointRouteCoords"
			:stoppoint-decision="getStoppointRouteDecision"
			:add-save-marker="getSaveMarker"
			:remove-exception-marker="getExceptionMarker"
			:close-popups="getPopups"
			:replace-exception-popup="getExceptionPopupReplacement"
		>
		</Map>
		<div class="mc--overlay">
			<Modal
				:show="isModalShown"
				headline="Matrix Routing"
				:steps="steps"
			>
			</Modal>
		</div>
		<div class="logo">
		</div>
	</div>
</template>

<script>
import Modal from "../components/Modal"
import Map from "../components/Map"

import { mapGetters } from "vuex"

export default {
	name: "MapContainer",
	components: {
		Modal,
		Map
	},
	data() {
		return {
			steps: [
				{
					headline: "HERE Matrix Routing",
					subheadline: "Matrix Routing",
					content: {
						image: "./api-table-hires.png"
					},
					label: "Matrix"
				},
				{
					headline: "Lowest Cost Calculation",
					subheadline: "Matrix Routing",
					content: {
						image: "./api-graph-hires.gif"
					},
					label: "Iterations"
				}
			]
		}
	},
	computed: {
		...mapGetters([
			"getZoom",
			"getCenter",
			"getOrders",
			"getWarehouses",
			"getDepots",
			"getConstructionsitesMarker",
			"getBlockingMarker",
			"showLayer",
			"isModalShown",
			"showRoutes",
			"showSingleRoute",
			"getWalker",
			"getShowManyTrucks",
			"getAddExceptionMarker",
			"getSingleExceptionRoute",
			"getStoppointLayer",
			"getStoppointRouteCoords",
			"getStoppointRouteDecision",
			"getSaveMarker",
			"getExceptionMarker",
			"getPopups",
			"showLayerOrders",
			"showLayerWarehouses",
			"showLayerDepots",
			"getExceptionPopupReplacement"
		])
	}
}
</script>

<style scoped>
.mc--container {
	width: 100%;
	height: 100%;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.mc--image {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
}

.mc--overlay {
	z-index: 2;
	position: absolute;
}

.logo {
	position: absolute;
	bottom: 1rem;
	right: 1rem;
	height: 3rem;
	width: 3rem;
	background: url("../../statics/images/icons/darklogo.png");
	background-repeat: no-repeat;
	background-size: contain;
	z-index: 1;
}
</style>
