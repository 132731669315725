<template>
	<div class="ptv--container">
		<h1>{{ headline }}</h1>
		<div class="phone--navbar">
			<button
				class="phone--navbar-jobs"
				:class="{ 'button-active': getPhoneTabIndex === 1 }"
				@click="showJobs"
			>
				<img src="../../statics/images/icons/icon-list.svg">
				<span>Jobs</span>
				<div
					class="indicator"
					:class="{ 'indicator-active': getPhoneTabIndex === 1 }"
				></div>
			</button>
			<button
				class="phone--navbar-navigation"
				:class="{ 'button-active': getPhoneTabIndex === 0 }"
				@click="showNavigation"
			>
				<img src="../../statics/images/icons/icon-map.svg">
				<span>Navigation</span>
				<div
					class="indicator"
					:class="{ 'indicator-active': getPhoneTabIndex === 0 }"
				></div>
			</button>
		</div>
		<div
			v-show="getPhoneTabIndex > 1"
			class="phone--content"
		>
			<img src="../../statics/images/phone-avatar.png">
			<span class="phone--name">Joe</span>
			<span class="phone--jobtitle">Driver</span>
		</div>
		<div
			v-show="getPhoneTabIndex === 1"
			class="phone--content"
		>
			<div class="phone--list--lineconnection"></div>
			<ul>
				<li
					v-for="(item, index) in list"
					:key="index"	
				>
					<h1>{{ item.headline }}</h1>
					<div
						v-for="(subitem, subindex) in item.meta"
						class="phone--list--meta"
					>
						{{ subitem }}
					</div>
				</li>
			</ul>
		</div>
		<div
			v-show="getPhoneTabIndex === 0"
			class="phone--content phone--content-max"
		>
			<LUIButton
				membrane="aqua"
				button-label="Start Tour"
				size="medium"
				:click-event="switchView"
			></LUIButton>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex"
import LUIButton from "./LUIButton"

export default {
	name: "PhoneTabbedView",
	components: {
		LUIButton
	},
	props: {
		headline: {
			type: String,
			default: ""
		}
	},
	data() {
		return {
			list: [
				{
					headline: "Job 1",
					meta: [
						"ETA planned: 14/10/19, 09:00",
						"ETA actual: 14/10/19, 09:02 + 2min"
					]
				},
				{
					headline: "Job 2",
					meta: [
						"ETA planned: 14/10/19, 09:00",
						"ETA actual: 14/10/19, 09:02 + 2min"
					]
				},
				{
					headline: "Job 3",
					meta: [
						"ETA planned: 14/10/19, 09:00",
						"ETA actual: 14/10/19, 09:02 + 2min"
					]
				},
				{
					headline: "Job 4",
					meta: [
						"ETA planned: 14/10/19, 09:00",
						"ETA actual: 14/10/19, 09:02 + 2min"
					]
				},
				{
					headline: "Job 5",
					meta: [
						"ETA planned: 14/10/19, 09:00",
						"ETA actual: 14/10/19, 09:02 + 2min"
					]
				},
			]
		}
	},
	computed: {
		...mapGetters([
			"getPhoneTabIndex"
		])
	},
	methods: {
		switchView: function () {
			this.$store.commit("triggerWalker", true)
			this.$store.commit("setShowNavigationView", true)
		},
		showJobs: function () {
			this.$store.commit("setPhoneTabIndex", 1)
		},
		showNavigation: function () {
			this.$store.commit("setPhoneTabIndex", 0)
		}
	}
}
</script>

<style scoped>
	.ptv--container {
		height: 100%;
	}

	h1 {
		color: #fff;
		font-family: "FiraGO Bold", sans-serif;
		font-size: 1.2rem;
		margin: 0 0 1rem 0;
		text-align: center;
	}

	.phone--navbar {
		width: 100%;
		height: 4rem;
		display: flex;
		flex-direction: row;
	}

	.phone--navbar-jobs,
	.phone--navbar-navigation {
		background: #08A0FF;
		width: 50%;
		border: none;
		color: #fff;
		font-family: "FiraGo Light", sans-serif;
		font-size: 0.75rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding-top: 0.5rem;
	}

	.button-active {
		background: #0B94EA;
	}

	button img {
		height: 1.25rem;
		margin-bottom: 0.325rem;
	}

	.indicator {
		margin-top: 0.25rem;
		width: 4rem;
		height: 0.15rem;
		background-color: #fff;
		opacity: 0;
	}

	.indicator-active { opacity: 1; }

	.phone--content {
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;
	}

	.phone--list--lineconnection {
		position: absolute;
		width: 0.125rem;
		height: 70%;
		background: #fff;
		top: 2.4rem;
		left: 2.4rem;
	}

	.phone--content-max {
		height: 80%;
		justify-content: flex-end;
	}

	.phone--content img {
		margin-top: 3rem;
		width: 11rem;
	}

	.phone--content span {
		margin-top: 0.5rem;
		color: #fff;
	}

	.phone--name {
		font-family: "FiraGO Light", sans-serif;
		font-size: 1.7rem;
	}

	.phone--jobtitle {
		font-family: "FiraGO Light", sans-serif;
		font-size: 1rem;
	}

	ul {
		width: 80%;
		list-style: circle url("../../statics/images/icons/bulletpoint.png");
	}

	li {
		color: #fff;
		margin: 0.625rem;
		padding: 0 1rem;
	}

	li h1 {
		font-size: 1.2rem;
		font-family: "FiraGo Light", sans-serif;
		font-weight: 300;
		margin: 0 0 0.1rem 0;
		text-align: left;
	}

	.phone--list--meta {
		font-size: 0.6125rem;
		line-height: 1rem;
		color: #fff;
	}
</style>
