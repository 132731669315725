<template>
	<div class="sidebar--container">
		<div class="sidebar--navigation">
			<div
				@click="resetEventBusiness"
			>
				<RouterLink
					class="sidebar--item"
					active-class="sidebar--item-active"
					to="/app/business"
				>
					<div class="sidebar--item--indicator">
					</div>
					<div class="sidebar--item--icon icon-buildings not-selectable">
					</div>
					<div class="sidebar--item--label not-selectable">
						Business
					</div>
				</RouterLink>
			</div>
			<div
				@click="resetEventRouting"
			>
				<RouterLink
					class="sidebar--item"
					active-class="sidebar--item-active"
					to="/app/routing"
				>
					<div class="sidebar--item--indicator">
					</div>
					<div class="sidebar--item--icon icon-routing not-selectable">
					</div>
					<div class="sidebar--item--label not-selectable">
						Optimize
					</div>
				</RouterLink>
			</div>
			<div
				@click="resetEventMobileSDK"
			>
				<RouterLink
					class="sidebar--item"
					active-class="sidebar--item-active"
					to="/app/mobilesdk"
				>
					<div class="sidebar--item--indicator">
					</div>
					<div class="sidebar--item--icon icon-mobilesdk not-selectable">
					</div>
					<div class="sidebar--item--label not-selectable">
						Mobile SDK
					</div>
				</RouterLink>
			</div>
			<div
				@click="resetEventStoppoint"
			>
				<RouterLink
					class="sidebar--item"
					active-class="sidebar--item-active"
					to="/app/stoppoint"
				>
					<div class="sidebar--item--indicator">
					</div>
					<div class="sidebar--item--icon icon-stoppoint not-selectable">
					</div>
					<div class="sidebar--item--label not-selectable">
						Stop Point
					</div>
				</RouterLink>
			</div>
			<div
				@click="resetEventException"
			>
				<RouterLink
					class="sidebar--item"
					active-class="sidebar--item-active"
					to="/app/exception"
				>
					<div class="sidebar--item--indicator">
					</div>
					<div class="sidebar--item--icon icon-exception not-selectable">
					</div>
					<div class="sidebar--item--label not-selectable">
						Exception
					</div>
				</RouterLink>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "LuiSidebarSequential",
	data () {
		return {
			routes: this.$router.options.routes
		}
	},
	methods: {
		showFor: function (routeName) {
			if (this.$route.name.includes(routeName)) {
				return true
			} else {
				return false
			}
		},
		resetEventBusiness: function () {
			this.$store.dispatch("resetAppState")
			this.$store.commit("setZoom", 14)
			this.$store.commit("setCenter", {
				lat: 36.15438,
				lng: -115.15471
			})
			this.$store.commit("showAllLayers", false)
			//TODO quick fix to prevent resetAllLayers mutation being fired before showAllLayers has been watched and executed by Map.vue
			setTimeout(() => {
				this.$store.commit("resetAllLayers")
			}, 100)
		},
		resetEventRouting: function () {
			this.$store.dispatch("resetAppState")
			this.$store.commit("setZoom", 14)
			this.$store.commit("setCenter", {
				lat: 36.15438,
				lng: -115.15471
			})
		},
		resetEventMobileSDK: function () {
			this.$store.dispatch("resetAppState")
			this.$store.commit("setZoom", 14)
			this.$store.commit("setCenter", {
				lat: 36.15438,
				lng: -115.15471
			})
			this.$store.commit("showSingleRoute", true)
		},
		resetEventStoppoint: function () {
			this.$store.dispatch("resetAppState")
			this.$store.commit("setZoom", 17)
			this.$store.commit("setCenter", {
				lat: 36.197,
				lng: -115.21
			})
			this.$store.commit("showAllLayers", false)
			this.$store.commit("showStoppointLayer", true)

			this.$store.commit("showDecisionButtons")

			// TODO error getPoint from undefined happening
			this.$store.commit("showSaveButton", false)
			this.$store.commit("setSaveMarker", false)
			//
		},
		resetEventException: function () {
			this.$store.dispatch("resetAppState")
			this.$store.commit("setZoom", 14)
			this.$store.commit("setCenter", {
				lat: 36.15738,
				lng: -115.15471
			})
			this.$store.commit("showManyTrucks", true)
			this.$store.commit("hideLayer", "orders")
			this.$store.commit("showRoutes", true)
		}
	}
}
</script>

<style scoped>
	.sidebar--container {
		background: #272D37;
		display: flex;
		flex-direction: row;
	}

	.sidebar--navigation {
		width: 6.25em;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: stretch;
		border-right: 0.125rem solid rgba(39, 45, 55, 0.5);
	}

	.sidebar--content {
		height: 100%;
		display: flex;
		flex-direction: column;
		width: 25em;
		border-left: 0.0625em solid rgb(71, 76, 85);
	}

	.sidebar--item,
	.sidebar--item--back {
		height: 6.25em;
		width: 6.25em;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		text-decoration: none;
		outline: none;
		opacity: 0.4;
		position: relative;
	}

	.sidebar--item--indicator {
		width: 0.1875em;
		height: 5em;
		position: absolute;
		background: rgba(45, 213, 201, 1);
		left: 0.4em;
		top: 0.625em;
		opacity: 0;
	}

	.sidebar--item-active {
		opacity: 1;
	}

	.sidebar--item-active > .sidebar--item--indicator {
		opacity: 1;
	}

	.sidebar--item--backarrow {
		width: 1.625em;
		height: 1.5em;
		background: url("../../statics/images/backarrow.svg");
		background-repeat: no-repeat;
		background-size: contain;
	}

	.sidebar--item--back:hover > .sidebar--item--backarrow {
		background: url("../../statics/images/backarrow-hover.svg");
	}
	
	.sidebar--item--back:active > .sidebar--item--backarrow {
		background: url("../../statics/images/backarrow-pressed.svg");
	}

	.sidebar--item--back-disabled > .sidebar--item--backarrow {
		background: url("../../statics/images/backarrow-disabled.svg");
	}

	.sidebar--item--icon {
		width: 2.5em;
		height: 2.5em;
		margin-bottom: 0.5em;
	}

	.sidebar--item--label {
		font-size: 0.8125em;
		color: rgba(255, 255, 255, 1);
	}

	.sidebar--item:hover {
		opacity: 1;
	}

	.sidebar--item:active {
		opacity: 0.1;
	}

	.icon-business {
		background: url("../../statics/images/building.svg");
		background-repeat: no-repeat;
		background-size: contain;
	}

	.icon-routing {
		background: url("../../statics/images/routing.svg");
		background-repeat: no-repeat;
		background-size: contain;
	}
	.icon-mobilesdk {
		background: url("../../statics/images/mobilesdk.svg");
		background-repeat: no-repeat;
		background-size: contain;
	}
	
	.icon-stoppoint {
		background: url("../../statics/images/stoppoint.svg");
		background-repeat: no-repeat;
		background-size: contain;
	}

	.icon-exception {
		background: url("../../statics/images/exception.svg");
		background-repeat: no-repeat;
		background-size: contain;
	}

	.icon-buildings {
		background: url("../../statics/images/building.svg");
		background-repeat: no-repeat;
		background-size: contain;
	}

	.icon-back {
		background: url("../../statics/images/backarrow.svg");
		background-repeat: no-repeat;
		background-size: contain;
	}
</style>
