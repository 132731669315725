<template>
	<div class="sc--places--container">
		<div class="sc--places--headline">
			HERE Mobile SDK
		</div>
		<div class="sc--places--subheadline">
			Driver support and communication
		</div>
		<div class="sc--places--description">
		</div>
		<div
			class="sc--places--selection"
		>
			<Phone></Phone>
		</div>
	</div>
</template>

<script>
import Phone from "./Phone"

export default {
	name: "SidebarContentBusiness",
	components: {
		Phone
	},
	data () {
		return {
			step: 0
		}
	},
	computed: {
	},
	methods: {
	},
	mounted () {
		this.$store.commit("showSingleRoute", true)
	}
}
</script>

<style scoped>
.sc--places--container {
	display: flex;
	flex-direction: column;
}

.sc--places--label {
	color: rgba(255, 255, 255, 0.6);
	text-decoration: underline;
	margin-bottom: 1.25em;
	font-size: 0.8125em;
}

.sc--places--headline {
	color: rgba(255, 255, 255, 1);
	margin-bottom: 0.625em;
	font-size: 2em;
	font-family: "FiraGo Bold", sans-serif;
}

.sc--places--subheadline {
	color: rgba(255, 255, 255, 0.6);
	text-transform: uppercase;
	font-family: "FiraGo Medium", sans-serif;
	font-size: 0.8125em;
	margin-bottom: 1.75em;
}

.sc--places--description {
	color: rgba(255, 255, 255, 0.8);
	font-size: 1em;
	line-height: 1.625em;
	margin-bottom: 4em;
}

.sc--places--button {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

.sc--places--selection {
	margin-bottom: 1.25em;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.sc--places--selection img {
	height: 50%;
	width: 50%;
	cursor: pointer;
}
</style>
