<template>
	<button
		:class="buttonClassObject"
		@touchstart="clickEvent"
		@click="clickEvent"
	>
		<div
			v-if="!isLoading"
			class="button--label"
		>
			{{ buttonLabel }}
		</div>
		<div
			v-if="isLoading"
			class="button--loading"
		>
			<svg
				height="2.5em"
				width="2.5em"
				class="loading--spinner"
			>
				<circle
					cx="1.25em"
					cy="1.25em"
					r="0.75em"
					stroke="rgba(15, 22, 33, 0.8)"
					stroke-width="0.25em"
					fill="none"
				>
				</circle>
			</svg>
			<svg
				height="2.5em"
				width="2.5em"
			>
				<circle
					cx="1.25em"
					cy="1.25em"
					r="0.75em"
					stroke="rgba(15, 22, 33, 0.4)"
					stroke-width="0.25em"
					fill="none"
				>
				</circle>
			</svg>
		</div>
	</button>
</template>

<script>
export default {
	name: "LUIButton",
	props: {
		membrane: {
			type: String,
			default: ""
		},
		buttonLabel: {
			type: String,
			default: ""
		},
		size: {
			type: String,
			default: ""
		},
		navigationPath: {
			type: String,
			default: ""
		},
		clickEvent: {
			type: Function,
			default() {
				return function() {}
			}
		},
		primary: {
			type: Boolean
		},
		isLoading: {
			type: Boolean
		}
	},
	computed: {
		buttonClassObject: function () {
			const buttonAqua = (this.membrane === "aqua") ? true : false
			const buttonContext = (this.membrane === "context") ? true : false
			const buttonContent = (this.membrane === "content") ? true : false
			const buttonLarge = (this.size === "large") ? true : false
			const buttonMedium = (this.size === "medium") ? true : false
			const buttonSmall = (this.size === "small") ? true : false

			return {
				"button--container": true,
				"button-large": buttonLarge,
				"button-medium": buttonMedium,
				"button-small": buttonSmall,
				"button-aqua": buttonAqua,
				"button-context": buttonContext,
				"button-content": buttonContent,
				"button-primary": this.primary,
				"button-secondary": !this.primary
			}
		}
	}
}
</script>

<style scoped>
button {
	padding: 0;
	border: none;
	background: none;
}

.button--container {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 0.0625em;
	cursor: pointer;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.button-large {
	width: 23.75em;
	height: 3.75em;
	font-size: 1.375em;
}

.button-medium {
	width: 11.25em;
	height: 2.5em;
	font-size: 1em;
}

.button-small {
	width: 11.25em;
	height: 1.875em;
	font-size: 0.8125em;
}

.button-primary.button-context {
	background: rgba(45, 213, 201, 1);
	color: rgba(15, 22, 33, 0.8);
}

.button-primary.button-context:hover {
	background: rgba(118, 227, 220, 1);
}

.button-primary.button-context:active {
	background: rgba(0, 182, 178, 1);
}

.button-primary.button-context:disabled {
	background: rgba(45, 213, 201, 0.2);
}

.button-secondary.button-context {
	border: 0.0625em solid rgba(45, 213, 201, 1);
	color: rgba(45, 213, 201, 1);
}

.button-secondary.button-context:hover {
	border: 0.0625em solid rgba(118, 227, 220, 1);
	color: 0.0625em solid rgba(118, 227, 220, 1);
}

.button-secondary.button-context:active {
	border: 0.0625em solid rgba(0, 182, 178, 1);
	color: 0.0625em solid rgba(0, 182, 178, 1);
}

.button-secondary.button-context:disabled {
	border: 0.0625em solid rgba(45, 213, 201, 0.2);
	color: 0.0625em solid rgba(45, 213, 201, 0.2);
}

.button-primary.button-content {
	background: rgba(0, 182, 178, 1);
	color: rgba(255, 255, 255, 0.9);
}

.button-primary.button-content:hover {
	background: rgba(45, 213, 201, 1);
}

.button-primary.button-content:active {
	background: rgba(0, 144, 138, 1);
	color: rgba(15, 22, 33, 0.8);
}

.button-primary.button-content:disabled {
	background: rgba(0, 182, 178, 0.2);
	color: rgba(15, 22, 33, 0.6);
}

.button-secondary.button-content {
	border: 0.0625em solid rgba(0, 182, 178, 1);
	color: rgba(0, 182, 178, 1);
}

.button-secondary.button-content:hover {
	border: 0.0625em solid rgba(45, 213, 201, 1);
	color: 0.0625em solid rgba(45, 213, 201, 1);
}

.button-secondary.button-content:active {
	border: 0.0625em solid rgba(0, 144, 138, 1);
	color: 0.0625em solid rgba(0, 144, 138, 1);
}

.button-secondary.button-content:disabled {
	border: 0.0625em solid rgba(0, 182, 178, 0.2);
	color: 0.0625em solid rgba(0, 182, 178, 0.2);
}

.button-aqua {
	background: rgba(255, 255, 255, 1);
	color: #00B6B2;
}

.button-aqua:hover {
	background: rgba(255, 255, 255, 0.85);
}

.button-aqua:active {
	background: rgba(15, 22, 33, 0.2);
}

.button-aqua:disabled {
	background: rgba(255, 255, 255, 0.4);
}

.button--loading {
	height: 2.5em;
	width: 2.5em;
	position: relative;
}

.button--loading svg {
	position: absolute;
	top: 0;
	left: 0;
}

.loading--spinner {
	clip-path: polygon(100% 0, 0 0, 50% 50%);
	animation: rotate 0.8s ease-in-out infinite;
}

@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}
	5% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
</style>
