<template>
	<div class="cb--container">
		<input
			v-model="layer"
			type="checkbox"
			class="cb--input"
		>
		<span
			class="cb--label"
		>
			{{ label }}
		</span>
	</div>
</template>

<script>
import { mapGetters } from "vuex"
export default {
	name: "LUICheckbox",
	props: {
		label: {
			type: String,
			default: ""
		},
		id: {
			type: String,
			default: ""
		}
	},
	computed: {
		...mapGetters([
			"showLayerOrders",
			"showLayerWarehouses",
			"showLayerDepots"
		]),
		layer: {
			get () {
				switch (this.id) {
				case "orders":
					return this.showLayerOrders
				case "warehouses":
					return this.showLayerWarehouses
				case "depots":
					return this.showLayerDepots
				default:
					return null
				}
			},
			set () {
				this.$store.commit("setLayer", this.id)
			}
		}
	}
}
</script>

<style scoped>
.cb--container {
	display: flex;
	align-items: center;
	margin-bottom: 1.25rem;
}

input[type=checkbox] {
	width: 1.25rem;
	height: 1.25rem;
	margin-right: 8px;
	cursor: pointer;
	font-size: 1rem;
	visibility: hidden;
}

input[type=checkbox]:after {
	content: " ";
	background: #2DD5C9;
	display: flex;
	justify-content: center;
	color: #000;
	width: 1.25rem;
	height: 1.25rem;
	visibility: visible;
	border-radius: 1px;
}

@media screen and (max-width: 3839px) {
	input[type=checkbox]:checked:after {
		content: url("../../statics/images/checkboxtick_lowres.png");
		font-family: "FiraGO Regular", sans-serif;
	}
}

@media screen and (min-width: 3840px) {
	input[type=checkbox]:checked:after {
		content: url("../../statics/images/checkboxtick.png");
		font-family: "FiraGO Regular", sans-serif;
	}
}

.cb--label {
	font-family: "FiraGO Regular", sans-serif;
	font-size: 1rem;
	line-height: 1.25rem;
	color: rgba(255, 255, 255, 0.8);
	margin-left: 0.3rem;
}
</style>
