<template>
	<div class="ac--container">
		<LUISidebar></LUISidebar>
		<div class="ac--content">
			<div class="ac--sidebar">
				<RouterView name="sidebar">
				</RouterView>
			</div>
			<div class="ac--content--views">
				<RouterView></RouterView>
			</div>
		</div>
	</div>
</template>

<script>
import LUISidebar from "../components/LUISidebar"

export default {
	name: "AppContainer",
	components: {
		LUISidebar
	}
}
</script>

<style scoped>
.ac--container {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 100%;
}

.ac--sidebar {
	display: flex;
	flex-direction: column;
	padding: 1.25rem;
	background: #272D37;
	width: 25rem;
}

.ac--content {
	display: flex;
	width: 100%;
	height: 100%;
}

.ac--content--views {
	height: 100%;
	overflow-y: scroll;
	flex-grow: 1;
	background: rgba(243, 243, 244);
}

::-webkit-scrollbar {
	width: 0em;
}
</style>
