<template>
	<div class="fl--container">
		<div class="fl--tabbar">
			<button
				class="fl--tabbar--item"
				:class="{ 'active': showFleet }"
				@click="showFleet = true"
			>
				<span>Fleet</span>
				<span class="fl--tabbar--item--indicator">
				</span>
			</button>
			<button
				class="fl--tabbar--item"
				:class="{ 'active': !showFleet }"
				@click="showFleet = false"
			>
				<span>Drivers</span>
				<span class="fl--tabbar--item--indicator">
				</span>
			</button>
		</div>
		<div
			v-if="showFleet"
			class="fl--list"
		>
			<div
				v-for="(item, index) in content.fleet"
				:key="index"
				class="fl--list--item"
			>
				<div class="fl--list--item--pane pane--fleet-left">
					<h1>{{ item.headline }}</h1>
					<h2>{{ item.subheadline }}</h2>
					<div
						v-show="item.electric"
						class="fl--list--item--progressbar"
					>
					</div>
					<span>{{ item.percentage }} / {{ item.mileage }}</span>
				</div>
				<div class="fl--list--item--pane pane--fleet-right">
					<img :src="item.image">
					<span>capacity: {{ item.capacity }}kg</span>
					<span
						v-if="item.specials"
					>
						specials: {{ item.specials }}
					</span>
				</div>
			</div>
		</div>
		<div
			v-if="!showFleet"
			class="fl--list"
		>
			<div
				v-for="(item, index) in content.drivers"
				:key="index"
				class="fl--list--item"
			>
				<div class="fl--list--item--pane pane--driver-left">
					<h1>{{ item.headline }}</h1>
					<h2>{{ item.age }} - {{ item.status }}</h2>
					<span class="first">{{ item.hours }} hours / {{ item.jobs }} Jobs</span>
					<span>License: {{ item.license }}</span>
					<span>Trainings: {{ item.trainings }}</span>
				</div>
				<div class="fl--list--item--pane pane--driver-right">
					<img :src="item.image">
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "FleetList",
	props: {
		content: {
			type: Object,
			default() {
				return {}
			}
		}
	},
	data() {
		return {
			showFleet: true
		}
	}
}
</script>

<style scoped>
.fl--tabbar {
	display: flex;
	flex-direction: row;
}

.fl--tabbar--item {
	height: 2.5rem;
	width: 6.25rem;
	color: #fff;
	background: none;
	border: none;
	position: relative;
	outline: none;
}

.fl--tabbar--item--indicator {
	width: 4rem;
	height: 0.25rem;
	background: #2DD5C9;
	position: absolute;
	bottom: 0;
	left: 1.25rem;
	opacity: 0;
}

.active .fl--tabbar--item--indicator {
	opacity: 1;
}

.fl--list {
	display: flex;
	flex-direction: column;
	border: 0.125rem dotted rgb(104, 106, 111);
	padding: 1rem;
}

.fl--list--item {
	background: rgba(15, 22, 33, 0.5);
	height: 6.5rem;
	display: flex;
	flex-direction: row;
	padding: 1rem;
	margin-bottom: 1rem;
}

.fl--list--item--pane {
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.pane--fleet-left {
	width: 50%;
}

.pane--fleet-right {
	width: 45%;
	margin-left: 5%;
}

.pane--driver-left {
	width: 70%;
}

.pane--driver-left .first {
	margin-bottom: 0.5rem;
}

.pane--driver-right {
	width: 30%;
}

.pane--fleet-right img {
	height: 3rem;
	margin-bottom: 1rem;
}

.pane--driver-right img {
	height: 7rem;
	margin-bottom: 1rem;
}


h1 {
	font-family: "Fira Sans", sans-serif;
	font-size: 1.125rem;
	color: rgba(255, 255, 255, 0.8);
	margin: 0 0 0.25rem 0;
}

h2 {
	font-family: "Fira Sans", sans-serif;
	font-size: 0.8125rem;
	font-weight: 400;
	color: rgba(255, 255, 255, 0.2);
	margin: 0 0 0.375rem 0;
}

.fl--list--item--progressbar {
	background: #00BD7D;
	height: 0.25rem;
	width: 5rem;
	margin: 0 0 1rem 0;
}

span {
	font-family: "Fira Sans", sans-serif;
	font-size: 0.875rem;
	line-height: 1rem;
	color: rgba(255, 255, 255, 0.8);
}

</style>
