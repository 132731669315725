<template>
	<div class="sc--places--container">
		<div class="sc--places--headline">
			HERE Fleet Optimization API
		</div>
		<div class="sc--places--subheadline">
			Tour optimization and route planning
		</div>
		<div class="sc--places--selection">
			<img v-show="!isModalShown" src="../../statics/images/api-animation-placeholder.png">
			<img v-show="isModalShown" src="../../statics/images/api-animation.gif">
		</div>
		<div class="sc--places--selection">
			<LUIButton
				v-show="showButton"
				membrane="context"
				button-label="Call API"
				size="medium"
				:click-event="callApi"
				:primary="true"
			>
			</LUIButton>
		</div>
	</div>
</template>

<script>
import LUIButton from "./LUIButton"

import { mapGetters } from "vuex"

export default {
	name: "SidebarContentRouting",
	components: {
		LUIButton
	},
	data () {
		return {
			showButton: true
		}
	},
	computed: {
		...mapGetters([
			"isModalShown"
		])
	},
	methods: {
		callApi: function () {
			this.$store.commit("openModal")
			this.$store.commit("showRoutes", true)
			this.showButton = false
		}
	}
}
</script>

<style scoped>
.sc--places--label {
	color: rgba(255, 255, 255, 0.6);
	text-decoration: underline;
	margin-bottom: 1.25em;
	font-size: 0.8125em;
}

.sc--places--headline {
	color: rgba(255, 255, 255, 1);
	margin-bottom: 0.625em;
	font-size: 2em;
	font-family: "FiraGo Bold", sans-serif;
	width: 80%;
}

.sc--places--subheadline {
	color: rgba(255, 255, 255, 0.6);
	text-transform: uppercase;
	font-family: "FiraGo Medium", sans-serif;
	font-size: 0.8125em;
	margin-bottom: 1.75em;
	width: 80%;
}

.sc--places--description {
	color: rgba(255, 255, 255, 0.8);
	font-size: 1em;
	line-height: 1.625em;
	margin-bottom: 4em;
}

.sc--places--button {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

.sc--places--selection {
	margin-top: 1.5em;
	margin-bottom: 1.25em;
	display: flex;
	justify-content: center;
}

.sc--places--selection img {
	height: 100%;
	width: 100%;
}
</style>
