import Vue from "vue"
import Vuex from "vuex"

import content from "../statics/json/content.json"
import marker from "../statics/json/marker.json"

Vue.use(Vuex)

const store = new Vuex.Store({
	state: {
		content: content,
		marker: marker,
		center: {
			lat: 36.15438,
			lng: -115.15471
		},
		zoom: 13,
		showModal: false,
		stepperIndexActive: 0,
		showLayer: {
			orders: true,
			warehouses: false,
			depots: false
		},
		isDepotSelected: false,
		showRoutes: false,
		showSingleRoute: false,
		showNavigationView: false,
		walker: false,
		reachedDestination: false,
		showManyTrucks: false,
		addExceptionMarker: false,
		singleExceptionRoute: false,
		showStoppointLayer: false,
		stoppointRouteDecision: 0,
		decisionButtons: false,
		saveButton: false,
		saveMarker: false,
		exceptionMarker: false,
		popups: false,
		showApiView: false,
		showApiProcessView: false,
		navigation: {
			text: "",
			time: "",
			arrow: "straight",
			street: ""
		},
		phoneTabIndex: 2,
		exceptionPopupReplacement: false
	},
	getters: {
		getNavigation: state => state.navigation,
		getShowApiView: state => state.showApiView,
		getShowApiProcessView: state => state.showApiProcessView,
		getZoom: state => state.zoom,
		getStepperIndexActive: state => { return state.stepperIndexActive },
		isModalShown: state => { return state.showModal },
		getOrders: state => { return state.marker.delivery_orders },
		getSingleOrder: state => { return state.marker.delivery_orders.one },
		getWarehouses: state => { return state.marker.warehouses },
		getDepots: state => { return state.marker.depots },
		getConstructionsitesMarker: state => { return state.marker.constructionsites },
		getBlockingMarker: state => { return state.marker.blockings },
		getCenter: state => { return state.center },
		showLayer: state => state.showLayer,
		showLayerOrders: state => state.showLayer.orders,
		showLayerWarehouses: state => state.showLayer.warehouses,
		showLayerDepots: state => state.showLayer.depots,
		getContentBusiness: state => state.content.business,
		getContentNavigation: state => state.content.mobilesdk.phone.navigation,
		getContentNavigationStoppoint: state => state.content.mobilesdk.phone.stoppoint,
		getIsDepotSelected: state => state.isDepotSelected,
		showRoutes: state => state.showRoutes,
		showSingleRoute: state => state.showSingleRoute,
		getShowNavigationView: state => state.showNavigationView,
		getWalker: state => state.walker,
		getReachedDestination: state => state.reachedDestination,
		getShowManyTrucks: state => state.showManyTrucks,
		getAddExceptionMarker: state => state.addExceptionMarker,
		getSingleExceptionRoute: state => state.singleExceptionRoute,
		getStoppointLayer: state => state.showStoppointLayer,
		getStoppointRouteCoords: state => state.marker.stoppoint,
		getStoppointRouteDecision: state => state.stoppointRouteDecision,
		getShowDecisionButtons: state => state.decisionButtons,
		getSaveButton: state => state.saveButton,
		getSaveMarker: state => state.saveMarker,
		getExceptionMarker: state => state.exceptionMarker,
		getPopups: state => state.popups,
		getPhoneTabIndex: state => state.phoneTabIndex,
		getExceptionPopupReplacement: state => state.exceptionPopupReplacement
	},
	mutations: {
		setNavigation (state, value) {
			state.navigation = value
		},
		setShowApiView (state, value) {
			state.showApiView = value
		},
		setShowApiProcessView (state, value) {
			state.showApiProcessView = value
		},
		setCenter (state, object) {
			state.center = object
		},
		setStepperIndexActive (state, value) { state.stepperIndexActive = value },
		closeModal (state) { state.showModal = false },
		openModal (state) { state.showModal = true },
		setLayer (state, value) {
			state.showLayer[value] = !state.showLayer[value]
		},
		hideLayer (state, value) {
			state.showLayer[value] = false
		},
		resetAllLayers (state) {
			state.showLayer.orders = true
			state.showLayer.warehouses = false
			state.showLayer.depots = false
		},
		showAllLayers (state, value) {
			state.showLayer.orders = value
			state.showLayer.warehouses = value
			state.showLayer.depots = value
		},
		setIsDepotSelected (state, value) {
			state.isDepotSelected = value
		},
		showRoutes (state, value) {
			state.showRoutes = value
		},
		showSingleRoute (state, value) {
			state.showSingleRoute = value
		},
		setShowNavigationView (state, value) {
			state.showNavigationView = value
		},
		triggerWalker (state, value) {
			state.walker = value
		},
		reachedDestination (state, value) {
			state.reachedDestination = value
		},
		showManyTrucks (state, value) {
			state.showManyTrucks = value
		},
		setAddExceptionMarker (state, value) {
			state.addExceptionMarker = value
		},
		setSingleExceptionRoute (state, value) {
			state.singleExceptionRoute = value
		},
		showStoppointLayer (state, value) {
			state.showStoppointLayer = value
		},
		setStoppointRouteDecision (state, value) {
			state.stoppointRouteDecision = value
		},
		showDecisionButtons (state) {
			state.decisionButtons = true
		},
		hideDecisionButtons (state) {
			state.decisionButtons = false
		},
		setZoom (state, value) {
			state.zoom = value
		},
		showSaveButton (state, value) {
			state.saveButton = value
		},
		setSaveMarker (state, value) {
			state.saveMarker = value
		},
		setExceptionMarker (state, value) {
			state.exceptionMarker = value
		},
		setPopups (state, value) {
			state.popups = value
		},
		setPhoneTabIndex (state, value) {
			const index = parseInt(value)
			state.phoneTabIndex = index
		},
		setExceptionPopupReplacement (state, value) {
			state.exceptionPopupReplacement = value
		}
	},
	actions: {
		resetAppState ({ commit }) {
			commit("showRoutes", false)
			commit("closeModal")
			commit("setIsDepotSelected", false)
			commit("setPopups", true)
			commit("showAllLayers", true)
			commit("reachedDestination", false)
			commit("showManyTrucks", false)
			commit("triggerWalker", false)
			commit("showStoppointLayer", false)
			commit("hideDecisionButtons")
			//TODO combine following 2 commit to one
			commit("setAddExceptionMarker", false)
			commit("setExceptionMarker", true)
			//
			commit("setShowApiView", false)
			commit("setShowApiProcessView", false)
			commit("setShowNavigationView", false)
			commit("setSingleExceptionRoute", false)
			commit("setStoppointRouteDecision", 0)
			commit("setNavigation", {
				text: "",
				time: "",
				arrow: "straight",
				street: ""
			})
			commit("showSingleRoute", false)
			commit("setPhoneTabIndex", 2)
			commit("setExceptionPopupReplacement", false)
		}
	}
})

export default store
