import { Line } from "vue-chartjs"

export default {
	extends: Line,
	mounted() {
		this.renderChart({
			labels: [
				"",
				"",
				""
			],
			datasets: [
				{
					showLine: true,
					borderColor: "#555555",
					fill: false,
					data: [0.4, 0.4, 0.4],
					borderWidth: 6,
					borderDash: [ 16 ],
					pointRadius: 0
				},
				{
					showLine: true,
					borderColor: "#2DD5C9",
					backgroundColor: "#2DD5C9",
					data: [5, 1, 0.1],
					pointRadius: 0
				},
				{
					borderColor: "#47DBCF",
					backgroundColor: "#47DBCF",
					showLine: true,
					data: [6, 1.1, 0.1],
					pointRadius: 0
				},
				{
					showLine: true,
					borderColor: "#95EAE3",
					backgroundColor: "#95EAE3",
					data: [7, 1.2, 0.1],
					pointRadius: 0
				}
			]
		},{
			responsive: true,
			maintainAspectRadio: false,
			layout: {
				padding: 20
			},
			scales: {
				yAxes: [{
					scaleLabel: {
						display: true,
						labelString: "Cost",
						fontFamily: "FiraGo Regular",
						fontSize: "32"
					},
					gridLines: {
						display: false,
						drawBorder: true,
						lineWidth: 4,
						drawTicks: false,
						offsetGridLines: true,
						color: "#999999",
						zeroLineWidth: 0
					},
					ticks: {
						beginAtZero: false,
						display: false
					}
				}],
				xAxes: [{
					scaleLabel: {
						display: true,
						labelString: "Iterations",
						fontFamily: "FiraGo Regular",
						fontSize: "32"
					},
					gridLines: {
						display: false,
						drawBorder: true,
						lineWidth: 4,
						drawTicks: false,
						offsetGridLines: true,
						color: "#999999",
						zeroLineWidth: 0
					},
					ticks: {
						beginAtZero: false,
						display: false
					}
				}]
			},
			legend: {
				display: false
			},
			title: {
				display: false
			},
			tooltips: {
				enabled: false
			},
			elements: {
				point: {
					hoverRadius: 3
				},
				line: {
					tension: 0.2,
					borderWidth: 10
				}
			},
			animation: {
				duration: 0
			}
		})
	}
}
