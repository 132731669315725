<template>
	<div
		v-if="show"
		class="modal--container"
	>
		<div class="modal--topbar">
			<h2>{{ currentContent.subheadline }}</h2>
			<button
				@click="closeModal"
			>
			</button>
		</div>
		<div
			v-if="getStepperIndexActive === 0"
			class="modal--content"
		>
			<h1>{{ currentContent.headline }}</h1>
			<div class="modal--content--container">
				<img
					:src="currentContent.content.image"
					class="modal--content--image"
				>
			</div>
		</div>
		<div
			v-if="getStepperIndexActive === 1"
			class="modal--content"
		>
			<h1>{{ currentContent.headline }}</h1>
			<div class="modal--content--container">
				<div class="modal--graph--label label-x">Iterations</div>
				<div class="modal--graph--label label-y">Cost</div>
				<div class="modal--graph--label label-flag">Minimum cost</div>
				<svg class="graph" :view-box.camel="'0 0 1303 976'" fill="none" xmlns="http://www.w3.org/2000/svg">
					<line x1="103.5" y1="2.18557e-08" x2="103.5" y2="900" stroke="#CECECE"/>
					<line x1="1303" y1="900.5" x2="103" y2="900.5" stroke="#CECECE"/>
					<path class="graph--line" d="M159.692 164.895L155.752 165.585L159.692 164.895ZM873 830.972L872.642 834.956L872.815 834.971L872.99 834.972L873 830.972ZM135.434 49.4701L155.752 165.585L163.632 164.206L143.314 48.0912L135.434 49.4701ZM872.99 834.972L1254.99 835.972L1255.01 827.972L873.01 826.972L872.99 834.972ZM155.752 165.585C219.655 530.783 503.238 801.765 872.642 834.956L873.358 826.988C507.701 794.134 226.937 525.985 163.632 164.206L155.752 165.585Z" fill="#47DBCF"/>
					<line class="graph--dashed_line" x1="103" y1="834" x2="1303" y2="834" stroke="#444444" stroke-width="4" stroke-dasharray="32 16"/>
					<line class="graph--button_line" x1="871" y1="347" x2="871" y2="827" stroke="#C3C3C3" stroke-width="4"/>
					<circle class="graph--breakpoint" cx="872" cy="836" r="16" fill="#FEFEFE" stroke="#444444" stroke-width="8"/>
				</svg>
				<div class="modal--content--button">
					<LUIButton
						membrane="context"
						button-label="Show Results"
						size="medium"
						:click-event="closeModal"
						:primary="true"
					></LUIButton>
				</div>
			</div>
		</div>
		<LUIStepper
			:steps="steps"
			:active-step="getStepperIndexActive"
			:click-event="setStepperCount"
		>
		</LUIStepper>
	</div>
</template>

<script>
import LUIStepper from "./LUIStepper"
import LUIButton from "./LUIButton"
import LineChart from "./LineChart.js"
import { mapGetters } from "vuex"

export default {
	name: "Modal",
	components: {
		LUIStepper,
		LUIButton,
		LineChart
	},
	props: {
		headline: {
			type: String,
			default: ""
		},
		show: {
			type: Boolean
		},
		steps: {
			type: Array,
			default() {
				return []
			}
		}
	},
	computed: {
		currentContent: function () {
			const activeIndex = this.getStepperIndexActive
			return this.steps[activeIndex]
		},
		...mapGetters([
			"getStepperIndexActive"
		])
	},
	methods: {
		closeModal: function () {
			this.$store.commit("closeModal")
			this.$store.commit("setStepperIndexActive", 0)
		},
		setStepperCount: function (index) {
			this.$store.commit("setStepperIndexActive", index)
		}
	}
}
</script>

<style scoped>

h1, h2 {
	font-family: "FiraGO Regular", sans-serif;
	margin: 0;
}


.modal--container {
	width: 71.25rem;
	height: 53rem;
	background: #fff;
	z-index: 1;
	display: flex;
	flex-direction: column;
}

.modal--topbar {
	background: #F3F3F4;
	height: 4.25rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 2rem;
}

.modal--topbar h2 {
	font-size: 1.75rem;
	color: #3D434B;
}

.modal--topbar button {
	height: 1.75rem;
	width: 1.75rem;
	background: url("../../statics/images/close.svg");
	background-repeat: no-repeat;
	background-size: contain;
	border: none;
	cursor: pointer;
}

.modal--content {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding: 2rem;
}

.modal--content--container {
	margin-top: 2rem;
	flex-grow: 1;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	position: relative;
	height: 10rem;
	width: 67rem;
}

.modal--content--container img {
	width: 70%;
}

.modal--content--container p {
	width: 40%;
	color: rgba(15, 22, 33, 0.8);
	font-size: 1rem;
	line-height: 1.625rem;
	margin-left: 4rem;
}

.modal--content--button {
	position: absolute;
	top: 10rem;
	right: 21rem;
	opacity: 0;
	animation: fadein 0.8s 3s 1;
	animation-fill-mode: forwards;
}

.graph--line {
	clip-path: inset(0 100% 0 0);
	animation: reveal 3s 0s 1;
	animation-fill-mode: forwards;
}

.graph--dashed_line {
	opacity: 0;
	animation: fadein 0.8s 2s 1;
	animation-fill-mode: forwards;
}

.graph--button_line {
	opacity: 0;
	animation: fadein 0.8s 3s 1;
	animation-fill-mode: forwards;
}

.graph--breakpoint {
	opacity: 0;
	animation: fadein 0.8s 3s 1;
	animation-fill-mode: forwards;
}

@keyframes reveal {
	from {
		clip-path: inset(0 100% 0 0);
	}
	to {
		clip-path: inset(0 0 0 0);
	}
}

@keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.graph {
	width: 40.625rem;
	height: 30.5rem;
}

.modal--graph--label {
	position: absolute;
}

.label-x {
	bottom: 0rem;
	right: 14rem;
}

.label-y {
	top: 1rem;
	left: 12.5rem;
}

.label-flag {
	left: 8rem;
	bottom: 4.7rem;
}
</style>
